import {WebSocketLink} from '@apollo/client/link/ws';
import {ApolloClient, HttpLink, InMemoryCache, split} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';

export const DRIVO_URL = process.env.NODE_ENV === "production" ? "https://api.drivo.io" : "http://localhost:4000"
export const DRIVO_URL_WSS = process.env.NODE_ENV === "production" ? "wss://api.drivo.io" : "ws://localhost:4000"
const wsLink = new WebSocketLink({
    uri: `${DRIVO_URL_WSS}/graphql`,
    options: {
        reconnect: true,
        lazy: true
    }
});


const httpLink = new HttpLink({
    uri: `${DRIVO_URL}/graphql`,
});

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);
export default new ApolloClient({
    link: splitLink, cache: new InMemoryCache()
});
